/**
 * External dependencies
 */
import React from 'react';
import axios from 'axios';
import { withRouter } from "react-router-dom";

/**
 * Internal dependencies
 */
import {
  DEV_DASHBOARD,
  STAGE_DASHBOARD
} from '../../constants/apiContants';
import './style.scss';
import FuseboxOld from './fuseboxOld.png';
import FuseboxNew from './fuseboxNew.png';

function WelcomeForm({
  showError,
  userData,
  history,
}) {

  /**
   * Validate data in APIs
   *
   * @param  {string}  dashboardURL Dashboard URL.
   * @param  {string}  apiVersion   API version to be displayed in invalid password message.
   * @return {Promise}
   */
  const apiDashboardURL = async (dashboardURL, apiVersion) => {
    try {
      await axios.get(`${dashboardURL}sanctum/csrf-cookie`);
      
      const { status } = await axios.post(
        `${dashboardURL}api/user/login`,
        userData.loginData,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }
      );
      
      if ([200, 204].includes(status)) {
        
      } else {
        throw new Error();
      }
    } catch (e) {
      const link = document.createElement('a');

      link.setAttribute('href', dashboardURL);
      link.innerText = 'here';
      
      const message = e.response && 422 === e.response.status ?
        `The password you provided to your Fusebox ${apiVersion} dashboard is incorrect. Please input the correct password or login directly ${link.outerHTML}.`
        : 'Something went wrong. Please refresh the page and try again.';

      showError(message);
      redirectToLogin();
    }
  };

  /**
   *  Validate data in Dev API
   *  @param button
   *  return error or redirect to dashboard
   */
  const handleApi = (e) => {
    e.preventDefault();
    window.location.href = 'https://app.fusebox.fm';
  };

  /**
   *  Validate data in Dev API
   *  @param button
   *  return error or redirect to dashboard
   */
  const handleLegacyApi = (e) => {
    e.preventDefault();
    window.location.href = 'https://my.fusebox.fm';
  };

  const redirectToLogin = () => history.push('/login');

  return (
    <div className="Welcome">
      <p className="desc">
        Choose your dashboard below.
      </p>
      <div className="Welcome__wrapper">
        <div className="Welcome__wrapper__bottom d-flex">
          <div className="col">
            <div className="title-image">
              <h3>Fusebox 1.0</h3>
              <p className="plugin-info">plugin v2.13.0</p>
              <div className="image">
                <img src={FuseboxOld} alt="" />
              </div>
            </div>
            <div className="btn-text-wrapper">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleLegacyApi}
              >Sign In to Fusebox 1.0</button>
            </div>
          </div>
          <div className="col">
            <div className="title-image">
              <h3>Fusebox 2.0</h3>
              <p className="plugin-info">plugin v3.0.1</p>
              <div className="image">
                <img src={FuseboxNew} alt="" />
              </div>
            </div>
            <div className="btn-text-wrapper">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleApi}
              >Sign In to Fusebox 2.0</button>
              <p className="create-acc">
                Don’t have an account? <a href="https://app.fusebox.fm/register/">Create a free one.</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <p className="contact-us">
        Not sure what version you have? <a href="mailto:help@fusebox.fm">Contact us</a>
      </p>
    </div>
  )
}

export default withRouter(WelcomeForm);