/**
 * External dependencies
 */
import React from 'react';

/**
 * Internal dependencies
 */
import './style.scss';

const AlertComponent = ({ errorMessage, onClose }) => (
  <div
    className={"alert alert-danger alert-dismissable mt-4"}
    role="alert"
    id="alert-popup"
  >
    <div className="d-flex alertMessage">
      <span dangerouslySetInnerHTML={{ __html: errorMessage }} />
      <button type="button" className="close" aria-label="Close" onClick={onClose}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
);

export default AlertComponent