/**
 * External dependencies
 */
import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

/**
 * Internal dependencies
 */
import './styles/App.scss';
import { WelcomeForm, AlertComponent } from './components';
import { ReactComponent as Logo } from './logo.svg';

function App() {
  const [errorMessage, updateErrorMessage] = useState(null);

  return (
    <Router>
    <div className="App align-items-center justify-content-center">
        <div className="logo">
          <Logo />
        </div>
          <Switch>
            <Route path="/" exact={true}>
              <WelcomeForm />
            </Route>
          </Switch>
          {errorMessage && (
            <AlertComponent errorMessage={errorMessage} onClose={() => updateErrorMessage('')}/>
          )}
        </div>
    </Router>
  );
}

export default App;
